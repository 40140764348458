import React from "react";
import { Link, graphql } from "gatsby";
// import Button from "../components/button";
import Layout from "../components/layout";
import SEO from "../components/seo";

import PropTypes from "prop-types";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

import styled from "styled-components";
const Content = styled.div`
     {
        font-size: 90%;
    }
    h2 {
        font-size: 1.25rem;
    }
    h3 {
        font-size: 1.125rem;
    }
    h4 {
        font-size: 1.125rem;
        margin-bottom: 1.5rem;
        color: #1155cc;
    }
    hr {
        margin: 1rem 0;
    }
`;

function Page({ data }) {
    let d = data.allContentfulMinisite.nodes[0];

    const options = {
        renderMark: {
            [MARKS.CODE]: (embedded) => (
                <div dangerouslySetInnerHTML={{ __html: embedded }} />
            ),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                return <img src={`https:${node.data.target.fixed.src}`} />;
            },
        },
    };

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />

            <section className="bg-white dark:bg-gray-700 py-6">
                <div className="w-full max-w-3xl mx-auto border border-gray-200 dark:border-transparent rounded-xl shadow-lg overflow-hidden bg-white dark:bg-gray-800">
                    <img src={d.featuredImage.file.url} alt="" />
                    <Content className="p-6">
                        {renderRichText(d.content, options)}
                    </Content>
                    <div className="w-full my-10 flex justify-center">
                        <Link
                            to={d.cta.url}
                            target="_blank"
                            className="px-8 py-6 rounded-full font-bold text-2xl tracking-wide"
                            style={{
                                color: `#5E4A00`,
                                background: `linear-gradient(180deg, #FFE100 0%, #FFC000 100%)`,
                            }}
                        >
                            {d.cta.text}
                        </Link>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

Page.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        allContentfulMinisite(
            filter: { title: { eq: "Epic Cashback Program" } }
        ) {
            nodes {
                title
                featuredImage {
                    file {
                        url
                    }
                }
                content {
                    raw
                    references {
                        ... on ContentfulAsset {
                            contentful_id
                            __typename
                            fixed(width: 800) {
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
                cta {
                    url
                    text
                }
            }
        }
    }
`;

export default Page;
